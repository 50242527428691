import React from "react";
import './App.css';
import { ReactDOM } from "react";
import FetchMenu from "./FetchMenu";
class App extends React.Component {
   
    // Constructor 
    constructor(props) {
        super(props);
   
        this.state = {
            data: {},
            DataisLoaded: false
        };
    }
   
    
    // ComponentDidMount is used to
    // execute the code 
    componentDidMount() {
      fetch('http://menu.roydtechs.com/api/v1/meals')
          .then(response => response.json())
          .then(data => this.setState({ meals: data })
          
          );
          // console.log(this.state.todos)
  }
    render() {
        const { DataisLoaded, meals } = this.state;
        if (!DataisLoaded) return <div>
            <h1> Pleses wait some time.... </h1> </div> ;
   
        return (
          <div>
          {meals.map(meal => (
              <div key={meal.id}>
                  <h1>{meal.name}</h1>
                  <p>{meal.price}</p>
              </div>
          ))}
      </div>
  );
       
}
}
   

export default App;
