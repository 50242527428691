import React, { Component } from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';

function FetchMenu () {
    // const [records, setRecords] = useState([])
    const [records, setRecords] = useState ([])
       useEffect( ()=> {
axios.get('http://menu.roydtechs.com/api/v1/meals/1')
.then(res => {setRecords(res.data)})
.catch(err => console.log(err))

    },[])
    
   
        return (
            <div>
Menu
<table>
    <thead>
        <tr>
            <th>Menu</th>
            <th>Price</th>
        </tr>
    </thead>
    <tbody>
       
            {records.map((data) => {
        return (
          <div key={data.id}>
                <td>{data.name}</td>
       <td>{data.price}</td>
          </div>
        );
      })}
   
    </tbody>
</table>
            </div>
        );
    


    }

export default FetchMenu;