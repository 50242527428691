import axios from "axios";
// import React from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';
function App() {
    const [posts, setPosts] = useState([]);
  
    useEffect(() => {
      axios
        .get("http://menu.roydtechs.com/api/v1/meals")
        .then((result) => {
          console.log(result.data);
          setPosts(result.data);
        })
        .catch((error) => console.log(error));
    }, []);
//   if (!post) return null;

  return (
    <div>
        <Table  striped bordered hover><thead>
            <th></th>
<th>Menu</th>
<th>description</th>
<th>price</th>
        </thead> <tbody>
      {posts.map((data) => {
        return (
           
            <tr>
            <td></td>
          <td>{data.name}</td>
          <td>{data.description}</td>
          <td>{data.price}</td>
        </tr>
        
        );
      })}
      </tbody>
      </Table >
    </div>
  );
}
export default App;